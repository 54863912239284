<template>
    <div
        class="the-customer-switcher-dropdown"
        v-if="customer && (customer.isAllowedToSwitch || isCustomerSwitched)"
    >
        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

            <div>
                <div class="con-img">
                    <feather-icon icon="UsersIcon" svgClasses="w-4 h-4" />
                </div>
            </div>

            <vs-dropdown-menu class="vx-navbar-dropdown">
                <ul style="min-width: 19rem">

                    <li
                        class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                        @click="popupIsActive = true"
                        v-if="!isCustomerSwitched"
                    >
                        <feather-icon icon="UserPlusIcon" svgClasses="w-4 h-4" />
                        <span class="ml-2">Switch user</span>
                    </li>

                    <vs-divider class="m-1" v-if="isCustomerSwitched"/>

                    <li
                        class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                        @click="unSwitchUser"
                        v-if="isCustomerSwitched"
                    >
                        <feather-icon icon="UserXIcon" svgClasses="w-4 h-4" />
                        <span class="ml-2">Un-switch user</span>
                    </li>
                </ul>
            </vs-dropdown-menu>
        </vs-dropdown>

        <vs-popup title="Account switcher" :active.sync="popupIsActive">
            <input type="text" v-model="emailToSwitch" placeholder="admin@yooda.com" style="display: inline-block;width: 80%;margin-right: 10px;line-height: 22px;padding: 4px;border: 1px solid #dedede;border-radius: 3px" ref="emailToSwitch" v-on:keyup.enter="switchUser(true)">
<!--            <vs-input v-model="emailToSwitch" placeholder="admin@yooda.com" style="display: inline-block;width: 80%;margin-right: 10px;" ref="emailToSwitch"/>-->
            <vs-button color="primary" type="border" size="small" @click="switchUser" style="display: inline-block;height: 35px;" :disabled="!validateEmail()">Switch user</vs-button>
        </vs-popup>
    </div>
</template>

<script>
import Vue from 'vue'
import EmailValidator from 'email-validator'
import AuthService from '@/service/auth/AuthService.js'
import ImpersonateService from '@/service/impersonate/ImpersonateService'
// import apiCalls from "@/api/axios/configuration/project";

export default {
    data(){
        return {
            projectList: null,
            popupIsActive: false,
            emailToSwitch: null // used to sync input
        }
    },
    computed: {
        customer: {
            cache: false,
                get() {
                return this.$store.state.customersManager.customer
            }
        },
        subscription: {
            cache: false,
                get() {
                return this.$store.getters['customersManager/getActiveSubscription']()
            }
        },
        isCustomerSwitched: {
            cache: false,
            get() {
                return ImpersonateService.isCustomerSwitched
            }
        }
    },
    watch:{
        popupIsActive(){
            if(this.popupIsActive){
                this.$nextTick(() => this.$refs.emailToSwitch.focus())
            }
        }
    },
    methods: {
        validateEmail() {
            if(this.emailToSwitch){ this.emailToSwitch = this.emailToSwitch.trim() }
            return EmailValidator.validate(this.emailToSwitch)
        },
        async updateEnvironment (redirectToHome = false) {
            if (this.emailToSwitch) {
                ImpersonateService.emailToSwitch = this.emailToSwitch
            } else {
                ImpersonateService.emailToSwitch = null
            }
            await AuthService.retrieveSession()
            if(redirectToHome){
                location.href = '/'
            }
            else{
                await this.$router.push(this.$route.path).then(()=>{ location.reload() })
            }

        },
        async switchUser (redirectToHome = false) {

            if(this.validateEmail()){
                // this.popupIsActive = false
                try {

                    if (this.customer.email !== this.emailToSwitch) {
                        await this.updateEnvironment(redirectToHome)
                        await this.$vs.notify({
                            title: 'Success',
                            text: `switched to ${this.emailToSwitch}`,
                            iconPack: 'feather',
                            color: 'success'
                        })
                    }

                } catch (error) {
                    this.emailToSwitch = null
                    ImpersonateService.emailToSwitch = null
                    this.$log.error(error)
                    this.$vs.notify({
                        title: 'Error',
                        text: 'unable to switch',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }

            }
        },
        async unSwitchUser () {
            try {
                this.$vs.loading()
                this.emailToSwitch = null
                await this.updateEnvironment(true)
            } catch (error) {
                this.$log.error(error)
                this.$vs.notify({
                    title: 'Error',
                    text: 'unable to un-switch',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            } finally {
                this.$vs.loading.close()
            }
        }
    },
    created() {
        Vue.$theCustomerSwitcher = this
    },
    mounted() {

        if(this.customer && (this.customer.isAllowedToSwitch || this.isCustomerSwitched)){
            if(this.$route.query['auto-switch-user']){
                this.emailToSwitch = this.$route.query['auto-switch-user']
                this.emailToSwitch = this.emailToSwitch.replace(' ', '+')
                this.switchUser()
            }
        }
    }
}
</script>

<style lang="scss">
.the-customer-switcher-dropdown {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #e5e5e5;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #b46c6c;

}
</style>
