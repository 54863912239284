
<template>
    <div>
        <div class="v-application">
            <navigation-menu/>
        </div>

        <div class="router-view-container">
            <header-nav-bar/>
            <!--            zoom-fade / slide-fade / fade-bottom / fade / zoom-out -->
            <transition name="slide-fade" mode="out-in">
                <router-view @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
            </transition>
        </div>
    </div>

</template>


<script>


import NavigationMenu from "@/layouts/NavigationMenu.vue";
import HeaderNavBar from "@/layouts/HeaderNavBar.vue";

export default {
    components: {
        HeaderNavBar,
        NavigationMenu,
    },
    data() {
        return {
        }
    },

    mounted() {
        let socket = window.socketIo(`${process.env.VUE_APP_YOODA_PUSH_URL}`,{
            reconnection: false,
            query: { subscriptionId: this.$router.currentRoute.params.subscriptionId }
        });
        socket.on("reload", () => { window.location.reload() });
    }
}

</script>

<style lang="scss">
.router-view-container{
    padding: 0 30px 30px 290px;
    @media screen and (max-width: 960px) {
        padding: 0 30px 30px 30px;
    }
}
</style>
