<template>

    <div v-if="customer" class="profile-drop-down-container v-application">
        <div>
            <p>{{ `${customer.firstName} ${customer.lastName}` }}</p>
            <p>{{ customer.organization }}</p>
        </div>

        <div class="text-center">
            <v-menu
                offset-y
                left
                transition="slide-y-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        x-large
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                    >
                        mdi-account-circle
                    </v-icon>
                </template>

                <div style="width: 250px">
                    <span style="display: block;opacity: 0.7;font-size: 10px;margin:0;">{{ $t('component_profileDropDown_title') }}</span>

                    <subscription-plan-info/>

                    <v-divider/>

                    <div class="profile-drop-down-link" @click="profile" v-if="productFamily === 'yooda-one'">
                        <v-icon small>mdi-account-outline</v-icon>
                        <span class="ml-3">{{ $t('component_profileDropDown_account') }}</span>
                    </div>

                    <v-divider/>

                    <div class="profile-drop-down-link" @click="logout">
                        <v-icon small>mdi-logout</v-icon>
                        <span class="ml-3">{{ $t('component_profileDropDown_logout') }}</span>
                    </div>

                </div>
            </v-menu>
        </div>
    </div>

</template>

<script>
import SubscriptionPlanInfo from "@/components/yooda-components/SubscriptionPlanInfo.vue";
import authService from '@/service/auth/AuthService.js'
import {mapGetters} from "vuex";

export default {
    components:{
        SubscriptionPlanInfo
    },
    computed: {
        ...mapGetters({
            getActiveCustomerSubscription:"customersManager/getActiveCustomerSubscription",

        }),

        productFamily(){
            return process.env.VUE_APP_PRODUCT_FAMILY_HANDLE
        },
        customer: {
            cache: false,
            get() {
                return this.$store.state.customersManager.customer
            }
        },
    },
    methods: {
        async logout () {
            try {
                this.$vs.loading()
                await authService.redirectToExternalLogoutPage(window.location.origin)
            } catch (error) {
                this.$log.error('logout', error)
                this.$vs.notify({
                    title: 'Error',
                    text: 'unable to logout',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            } finally {
                this.$vs.loading.close()
            }
        },
        profile () {
            if(this.$route.name !=="subscription_info"){
                this.$router.push({ name: 'subscription_info', params: { subscriptionId: this.$route.params.subscriptionId } })
            }
        }
    },

}
</script>

<style lang="scss">
    .profile-drop-down-container{
        >div{
            display: inline-block;
            vertical-align: middle;
            &:nth-of-type(1){
                width: 116px;
                padding: 0;
                margin: 0;
            }
            &:nth-of-type(2){ width: 50px; }

            >p{
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 120px;
                overflow: hidden;
                text-align: right;
                &:nth-of-type(1){
                    font-size: 13px;
                    font-weight: 600;
                    text-transform: capitalize;
                }
                &:nth-of-type(2){
                    font-size: 10px;
                }
            }
        }


    }
    .profile-drop-down-link{
        padding: 8px 0;
        cursor: pointer;
        transition: background-color 0.5s;
        &:hover{
            background-color: #efefef;
            transition: background-color 0.5s;
        }
    }
</style>
