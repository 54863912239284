<template>

    <div class="v-application pt-6 mb-12" id="header-nav-bar">
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col
                        :xl="3" :lg="3" :md="6" :cols="6"
                        :order-xl="1" :order="1"
                        class="py-0"
                    >
                        <div v-if="displayProjectName">
                            <h3 class="text-h4 primary--text mb-2 mt-1 text-left" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;font-size: 24px !important">{{ project.data.name }}</h3>
                            <router-link :to="{name:'home'}">
                                <v-icon>mdi-chevron-left</v-icon><span class="grey--text text--darken-1 text-decoration-underline">{{ $t('component_headerNavBar_goToHome') }}</span>
                            </router-link>
                        </div>
                        <div v-else>
                            <div v-if="displayTrialing">
                                <p class="mt-1 mb-2" v-html="$t('component_headerNavBar_trialing_remaining').replace('[*DAYS-COUNT*]',remainingTrialing)"/>
                                <v-btn color="primary" @click="openPurchase" small>{{ $t('component_headerNavBar_trialing_turnPremium_btn') }}</v-btn>
                            </div>
                        </div>
                    </v-col>

                    <v-col
                        :xl="2" :lg="4" :md="4" :cols="12"
                        :order-xl="2" :order="2"
                        class="py-0 text-center"
                        v-if="$vuetify.breakpoint.name === 'xl'"
                    >
                        <div v-if="displayTrialing && displayProjectName">
                            <p class="mt-1 mb-2" v-html="$t('component_headerNavBar_trialing_remaining').replace('[*DAYS-COUNT*]',remainingTrialing)"/>
                            <v-btn color="primary" small @click="openPurchase">{{ $t('component_headerNavBar_trialing_turnPremium_btn') }}</v-btn>
                        </div>
                    </v-col>

                    <v-col
                        :xl="5" :lg="6"  :cols="12"
                        :order-xl="3" :order="3"
                        class="text-center"
                        :class="`${displayReportInformation && ($vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg')? 'border-nav' : ''}`"
                    >
                        <template v-if="displayReportInformation">
                            <global-filter/>
                        </template>
                    </v-col>

                    <v-col
                        :xl="2" :lg="3" :md="6" :cols="6"
                        :order-xl="4" :order-lg="4" :order="2"
                        class="text-right pl-0"
                    >
                        <div style="position:relative">
                            <i18n-switcher class="d-inline-block" style="margin-top: 12px; vertical-align: top"/>
                            <profile-drop-down class="d-inline-block" />
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <the-customer-switcher style="position: absolute;right: -12px; top: 0" />
    </div>
</template>


<script>
import ProfileDropDown      from "@/components/ProfileDropDown.vue"
import TheCustomerSwitcher from '@/components/yooda-components/TheCustomerSwitcher'
import GlobalFilter from "@/components/yooda-components/GlobalFilter";
import I18nSwitcher from "@/components/yooda-components/I18nSwitcher.vue";
import {mapGetters} from "vuex";

export default {
    components: {
        ProfileDropDown,
        TheCustomerSwitcher,
        I18nSwitcher,
        GlobalFilter,
    },
    data() {
        return {
            displayTrialing:false,
            remainingTrialing:0,
        }
    },
    computed: {

        ...mapGetters({
            activeSubscription:"customersManager/getActiveSubscription"
        }),

        purchasePlanUrl(){
            return new URL(`${process.env.VUE_APP_CUSTOMERS_MANAGER_FRONTEND_BASE_URI}/purchase/select-plan?subscription-id=${this.activeSubscription().id}`).href
        },
        displayProjectName(){
            return (
                this.$route.name !== 'projects' &&
                this.$route.name !== 'subscription_info' &&
                this.$route.name !== 'reporting_planning' &&
                this.$route.name !== 'external_sources_management' &&
                this.$route.name !=='report_template'
            ) && this.project.data
        },
        displayReportInformation(){
            return ['dashboard','dashboard_recommendations','traffic','market_segment','progression_axis','advertising','seo_visibility','local_visibility','competitor','website_pages', 'linking'].includes(this.$route.name)
        },
    },
    methods:{
        openPurchase(){
            window.open(this.purchasePlanUrl, '_blank')
        }
    },

    mounted() {
        if(this.activeSubscription().state === 'trialing'){
            this.displayTrialing = true
            this.remainingTrialing = parseInt((this.activeSubscription().trialEndedAt - new Date()) / (1000 * 60 * 60 * 24), 10);
        }
    }
}
</script>

<style lang="scss">

#header-nav-bar{
    position: sticky;
    top: 0;
    z-index: 200;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(44%, rgb(247 247 247 / 95%)), color-stop(73%, rgba(248, 248, 248, 0.46)), to(rgba(255, 255, 255, 0)));
    .border-nav{
        border-left: 1px solid #cecece;
        border-right: 1px solid #cecece;
    }
}

</style>
