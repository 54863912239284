<template>
    <v-navigation-drawer
        fixed
        permanent
        class="nav-menu-custom"
    >
        <div class="px-6 pt-6 pb-2 mb-10">
            <router-link to="/">
                <img :src="getLogoFromProductOption" style="width: 220px"/>
            </router-link>
        </div>

        <div v-for="(navMenuItem, index) in navMenuItems" :key="index" class="mt-8 pr-2 pl-3 ">
            <div v-if="displayItemMenu(navMenuItem)">
                <p class="nav-menu-custom-category-title">{{ $t(`navigationMenu_category_title_${navMenuItem.name}`) }}</p>
                    <div  v-for="(item, key) in navMenuItem.items" :key="key">
                        <router-link :to="{name:item.name}" class="nav-menu-item-link" :class="(item.name === 'dashboard' && $route.name === 'dashboard_recommendations') ? 'router-link-exact-active' : ''"  v-if="displayItemMenu(item)">
                            <v-icon>{{ item.icon }}</v-icon>
                            <span>
                                {{ $t(`navigationMenu_link_title_${item.name}`) }}
                            </span>
                        </router-link>
                    </div>
            </div>
        </div>

        <template v-slot:append>
            <div class="grey lighten-3 py-2 px-4" style="opacity: 0.65" v-if="productFamily === 'yooda-one'">
                <button data-sleek-feedback style="font-size: 13px" class="mr-4">
                    <i class="v-icon mdi mdi-lightbulb-on-outline" style="font-size: 17px !important;"></i>
                    {{ $t('navigationMenu_sleekPlan_suggest') }}
                </button>
                <button data-sleek-changelog data-badge-changelog style="font-size: 13px">
                    <i class="v-icon mdi mdi-alert-decagram-outline" style="font-size: 17px !important;"></i>
                    {{ $t('navigationMenu_sleekPlan_news') }}
                </button>
            </div>
        </template>
    </v-navigation-drawer>
</template>


<script>


import {mapState} from "pinia";

import {useComponentStore} from "@/stores/ComponentStore";
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            navMenuItems:[
                {
                    name:'projects',
                    displayContext:'home',
                    items:[
                        {name:'projects', icon:'mdi-home',displayContext:'always'},
                    ]
                },
                {
                    name:'reporting',
                    displayContext:'home',
                    items:[
                        {name:'reporting_planning', icon:'mdi-clock-outline',displayContext:'home'},
                        {name:'report_template', icon:'mdi-chart-box-plus-outline',displayContext:'home'},
                    ]
                },
                {
                    name:'apps',
                    projectItem:true,
                    displayContext:'project',
                    items:[
                        {name:'dashboard_recommendations', icon:'mdi-monitor-dashboard',displayContext:'project'},
                        {name:'seo_visibility', icon:'mdi-seal',displayContext:'project'},
                        {name:'advertising', icon:'mdi-bullseye',displayContext:'project'},
                        {name:'market_segment', icon:'mdi-tag-multiple',displayContext:'project'},
                        {name:'competitor', icon:'mdi-star',displayContext:'project'},
                        {name:'traffic', icon:'mdi-account-group',displayContext:'project'},
                        {name:'local_visibility', icon:'mdi-map-marker',displayContext:'project'},
                        {name:'website_pages', icon:'mdi-sitemap',displayContext:'project'},

                    ]
                },
                {
                    name:'action',
                    displayContext:'project',
                    items:[
                        {name:'progression_axis', icon:'mdi-compass',displayContext:'project'},
                        {name:'reporting', icon:'mdi-cloud-upload',displayContext:'project'},
                        {name:'export', icon:'mdi-table-arrow-right',displayContext:'project'},
                    ]
                },
                {
                    name:'settings',
                    displayContext:'always',
                    items:[
                        // {name:'subscription_info', icon:'mdi-account-circle',displayContext:'home'},
                        {name:'external_sources_management', icon:'mdi-swap-vertical',displayContext:'home'},

                        {name:'keywords_configuration', icon:'mdi-format-list-bulleted-square',displayContext:'project'},
                        {name:'segments_configuration', icon:'mdi-tag-multiple',displayContext:'project'},
                        {name:'competitors_configuration', icon:'mdi-star',displayContext:'project'},
                        {name:'analytics_project_configuration', icon:'mdi-chart-areaspline',displayContext:'project'},
                    ]
                },

            ]
        }
    },
    computed:{
        getLogoFromProductOption(){
            let logo = require('@/assets/images/yoodaOne_menu_logo.png')

            if(this.productFamily === 'noiise'){logo = require('@/assets/images/noiise_menu_logo.png')}
            return logo
        },
        ...mapGetters({
            hasLinking:"customersManager/getHasLinking",
        }),
        productFamily(){
            return process.env.VUE_APP_PRODUCT_FAMILY_HANDLE
        },
        ...mapState(useComponentStore, {
            getDefaultDashboardName: 'getDefaultDashboardName',
        })
    },
    watch:{
        getDefaultDashboardName(){
            this.setDefaultDashboardLink()
        }
    },
    methods:{
        setDefaultDashboardLink(){
            if(!localStorage.getItem('defaultDashboardName')){
                this.navMenuItems[2].items[0].name = 'dashboard_recommendations'
            }
            else{
                this.navMenuItems[2].items[0].name = localStorage.getItem('defaultDashboardName')
            }
        },

        displayItemMenu(item){
            switch (item.displayContext){
                case 'always':
                    return true;
                case 'home':
                    return !this.$route.params.projectId;
                case 'project':
                    return !!this.$route.params.projectId;
            }
        }
    },
    beforeMount() {

        if(this.hasLinking()){
            this.navMenuItems[2].items.push({name:'linking', icon:'mdi-link-variant',displayContext:'project'})
        }

        this.setDefaultDashboardLink()

        if(this.productFamily === 'yooda-one'){
            this.navMenuItems[4].items.unshift({name:'subscription_info', icon:'mdi-account-circle',displayContext:'home'})
        }
    },
}

</script>


<style lang="scss">
.nav-menu-custom{
    border: none;
    box-shadow: 0 4px 24px 0 rgba(34,41,47,.15)!important;
    .nav-menu-custom-category-title{
        margin-bottom: 8px;
        padding-left: 6px;
        font-size: 12px;
        display: block;
        font-weight: 500;
        text-transform: uppercase;
        color: #999;
    }
    .nav-menu-item-link{
        display: block;
        font-size: 15px;
        line-height: 35px;
        border-radius: 6px;
        padding-left: 10px;
        transition: padding-left 0.3s;
        color: grey;
        .v-icon{
            font-size: 20px;
            margin-right: 6px;
        }
        .v-icon,span{
            vertical-align: middle;
            line-height: 20px;
        }

        &.router-link-exact-active{
            background: linear-gradient(118deg,rgba($primary,1),rgba($primary,.7)) !important;
            color: #fafafa;
            .v-icon{ color: #fafafa; }
        }
        &:hover{
            background-color: #ececec;
            padding-left: 14px;
            transition: padding-left 0.3s;
        }
    }

    @media screen and (max-width: 960px) {
        visibility: hidden;
        z-index: 9999 !important;
    }

    &.show-nav-menu-overlay{
        visibility: visible !important;
    }
}
</style>
